import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import implementationStyles from "../common/implementation-feature/styles.module.scss"
import indicatorsStyles from "../common/indicators-feature/styles.module.scss"

import { pagesLinks } from "../../common/links"

export default [
	{
		title: "Облачная программа автоматизации \nдля паба",
		description: (
			<>
				Один онлайн-кабинет, где можно настроить все процессы бизнеса.
				В&nbsp;любой момент меняйте акции или обновляйте меню
				напитков&nbsp;&mdash; для этого не&nbsp;нужно быть в&nbsp;заведении. Все
				документы и&nbsp;накладные безопасно хранятся в&nbsp;облаке.
			</>
		),
		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/pab-feature-1.png"
				alt="программа для паба"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		reverse: true,
		className: styles.feature1,
		theme: "white",
	},
	{
		title: "Мульти-\nплатформенность",
		tabletTitle: "Мультиплатформенность",
		mobileTitle: "Мульти-\nплатформенно\nсть",
		description: (
			<p>
				Программу для пабов Quick Resto можно установить на&nbsp;различные
				платформы операционных систем: кассовый терминал работает
				на&nbsp;планшетах iOS, системах на&nbsp;ОС&nbsp;Windows, а&nbsp;также
				на&nbsp;терминалах Эвотор.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/pab-feature-2.png"
				alt="кассовый терминал для паба"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		reverse: true,
		className: styles.feature2,
		theme: "white",
		actionText: "Подробнее",
		actionLink: pagesLinks.terminal.href,
	},
	{
		title: "Удобная CRM-система автоматизации \nдля паба",
		description: (
			<p className={styles.smallDescription}>
				Создавай любые программы лояльности, чтобы привлекать гостей
				и&nbsp;увеличивать средний чек: акции &laquo;1+1&raquo;,
				&laquo;комбо&raquo;, &laquo;скидки по&nbsp;расписанию&raquo;
				и&nbsp;бонусная система. С&nbsp;автоматизацией паба руководитель
				получает отчёты для аналитики, которые помогут найти прибыльные
				и&nbsp;неприбыльные программы.
			</p>
		),
		img: (
			<StaticImage
				className={styles.image3}
				src="./assets/pab-feature-3.png"
				alt="crm для паба"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		className: styles.feature3,
		theme: "white",
		actionText: "Подробнее",
		actionLink: pagesLinks.marketing.href,
	},
	{
		title: "Контроль персонала",
		description: (
			<>
				Автоматизация паба поможет оценить: кто из&nbsp;персонала отстаёт
				по&nbsp;продажам, эффективно&nbsp;ли работает ваша новая система
				мотивации. Чтобы исключить злоупотребления, ограничьте доступ
				сотрудников к&nbsp;потенциально опасным возможностям.
			</>
		),
		img: (
			<StaticImage
				className={styles.image4}
				src="./assets/pab-feature-4.png"
				alt="программа учета паба"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		reverse: true,
		className: styles.feature4,
		theme: "white",
		actionText: "Подробнее",
		actionLink: pagesLinks.staff.href,
	},
	{
		title: "Техкарты и расчёт себестоимости",
		description: (
			<p className={styles.smallDescription}>
				Используй техкарты для закусок и&nbsp;раскладки для коктейлей. Система
				автоматически рассчитывает себестоимость блюд и&nbsp;напитков, поэтому
				легко держать под контролем фудкост и&nbsp;повышать маржинальность.
			</p>
		),
		img: (
			<StaticImage
				className={styles.image5}
				src="./assets/pab-feature-5.png"
				alt="программа для автоматизации паба"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		className: styles.feature5,
		theme: "white",
		actionText: "Подробнее",
		actionLink: pagesLinks.menu.href,
	},
	{
		title: "Программа для автоматизации продаж блюд и напитков",
		description: (
			<>
				<p>
					На&nbsp;кассовом терминале есть подсказки для барменов, направленные
					на&nbsp;увеличение продаж. Настрой акции &laquo;комбо&raquo; или
					скидку по&nbsp;расписанию, добавь модификаторы&nbsp;&mdash;
					дополнительные платные ингредиенты к&nbsp;основной позиции.
				</p>
				<p>
					Автоматизация паба позволит не&nbsp;останавливать продажи даже при
					отключении электричества или интернета.
				</p>
			</>
		),
		img: (
			<StaticImage
				className={styles.image6}
				src="./assets/pab-feature-6.png"
				alt="касса для паба"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		reverse: true,
		className: styles.feature6,
		theme: "white",
		actionText: "Подробнее",
		actionLink: pagesLinks.terminal.href,
	},
	{
		title: "Показатели всегда под рукой",
		description: (
			<p className={styles.smallDescription}>
				Используйте мобильное приложение Quick&nbsp;Resto Manager для быстрого
				удаленного доступа к статистике работы заведения. Вы сможете оценить
				эффективность программ лояльности, работу сотрудников, прибыльность блюд
				и многое другое.
			</p>
		),

		img: (
			<StaticImage
				className={indicatorsStyles.image}
				src="../common/indicators-feature/indicators-feature.png"
				alt="quick resto manager"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: indicatorsStyles.feature,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.indexQRM.href,
	},
	{
		title: "Внедрение автоматизации паба под ключ",
		description: (
			<>
				<p>От первой продажи до масштабирования.</p>
				<p>Quick Resto даст все инструменты:</p>
				<ul>
					<li>Приложения для управления и продаж</li>
					<li>Отчёты по продажам, блюдам, сотрудникам</li>
					<li>Складской учёт и технологические карты</li>
					<li>CRM-система и аналитика</li>
					<li>Сервис: установка, настройка, обучение</li>
					<li>Круглосуточная техподдержка</li>
				</ul>
			</>
		),

		img: (
			<StaticImage
				className={implementationStyles.image}
				src="../common/implementation-feature/implementation-feature.png"
				alt="Алексей Гаврилов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: implementationStyles.feature,
		theme: "white",
		backdrop: (
			<div className={implementationStyles.featureTopRightTrapezeBackdrop} />
		),
	},
]
