import React from "react"

import Inventory from "./assets/Inventory"
import Egais from "./assets/Egais"

export default [
	{
		icon: <Inventory />,
		title: "Инвентаризация паба",
		description: (
			<>
				Программа учёта для пабов упрощает инвентаризацию: после продажи напитка
				или блюда система списывает ингредиенты со&nbsp;склада по&nbsp;граммам.
				Вы&nbsp;видите то&nbsp;количество продуктов, которое должно остаться
				на&nbsp;складе.
			</>
		),
	},
	{
		icon: <Egais />,
		title: "ЕГАИС для паба",
		description: (
			<>
				Quick Resto поддерживает ЕГАИС&nbsp;&mdash; программу учёта алкоголя,
				закупок и&nbsp;вскрытия тары. Отправляй отчёты в&nbsp;ФСРАР
				в&nbsp;несколько кликов. Благодаря ЕГАИС можно понять сколько продано
				алкоголя.
			</>
		),
	},
]
