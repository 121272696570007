import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 535,
			tabName: "Отчеты",
			desktopTitle: <>Увеличивай средний чек и&nbsp;свою прибыль</>,
			desktopContent: (
				<p>
					Отчеты по&nbsp;продажам покажут, какие блюда приносят больше прибыли
					бизнесу. Отчеты по&nbsp;сотрудникам дадут возможность увидеть, кто
					продаёт лучше. Автоматический расчет зарплат добавит прозрачности
					и&nbsp;мотивации для персонала.
				</p>
			),
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/report-1.png"
					alt="отчеты по продажам в пабе"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 535,
			tabName: "CRM",
			desktopTitle:
				"Программа лояльности, как инструмент для привлечения гостей",
			desktopContent: (
				<p>
					Quick Resto поддерживает акции, бонусы, абонементы, <br />
					купоны, спецпредложения и&nbsp;другие программы лояльности.
					Мы&nbsp;бесплатно разработаем мобильное приложение для вашего паба,
					и&nbsp;ты&nbsp;сможешь предложить гостям доставку блюд
					и&nbsp;предзаказ с&nbsp;онлайн-оплатой.
				</p>
			),
			tabletContent: (
				<p>
					Quick Resto поддерживает акции, бонусы, абонементы, купоны,
					спецпредложения и&nbsp;другие программы лояльности. Мы&nbsp;бесплатно
					разработаем мобильное приложение для вашего паба, и&nbsp;ты&nbsp;сможешь
					предложить гостям доставку блюд и&nbsp;предзаказ
					с&nbsp;онлайн-оплатой.
				</p>
			),
			detailLink: pagesLinks.marketing,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/pab-report-2.png"
					alt="программы лояльности для паба"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
