import { contacts } from "../../../pages-data/_V2/common/contacts"
import React from "react"

export default {
	title: (
		<p>
			Если нужно открыть паб <br />
			как можно быстрее
		</p>
	),
	description: (
		<p>
			Мы&nbsp;зарегистрируем онлайн-кассу, подключим оборудование <br />
			и&nbsp;поможем заполнить данные в&nbsp;кабинете администратора. <br />
			Начни работу, не&nbsp;отвлекаясь на&nbsp;&laquo;операционку&raquo;.
		</p>
	),
	phone: contacts.phone,
	ctaButtonText: "Заказать звонок",
}
