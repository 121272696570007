import React from 'react'
import classNames from 'classnames'

import { requestForAutoOpenPubFaster, } from '../../apiRequests/callToAction'
import DefaultLayout from '../../layout/default'
import AutomationMenu from '../../components/_V2/AutomationMenu'
import SimpleFeatures from '../../components/_V2/SimpleFeatures'

import PabCtaForm from '../../pagesSections/avtomatizaciya-paba/PabCtaForm'
import Presentation
  from '../../pagesSections/avtomatizaciya-paba/ProductPresentation/assets/pab-presentation.png'
import PubBenefits from '../../pagesSections/avtomatizaciya-paba/PubBenefits'
import CtaForm from '../../pagesSections/index/CtaForm'

import ProductPresentation from '../../pagesSections/avtomatizaciya-paba/ProductPresentation'

import featuresData from '../../pages-data/_V2/automatization/pab/features'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/automation.module.scss'
import ReportFeatures from '../../pagesSections/avtomatizaciya-paba/ReportFeatures'
import AccountingFeaturesIntegrations from "../../pagesSections/intergations/AccountingFeatures"
import ProductsFeatures from "../../pagesSections/intergations/ProductsFeatures"
import AdditionalServices from "../../pagesSections/index/AdditionalServices"

export default function PageAvtomatizaciyaPaba() {
  const metaTags = [
    // {
    //   name: 'keywords',
    //   content: 'quick resto, автоматизация кофейни, ресторанный бизнес, программа учёта, склад, общепит',
    // },
    {
      name: 'description',
      content: 'Программа автоматизации паба ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ',
    },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/automation/avtomatizaciya-paba/',
    },
    {
      property: 'og:title',
      content: 'Автоматизация паба — комплексная программа учета | Quick Resto',
    },
    {
      property: 'og:description',
      content: 'Программа автоматизации паба ✔ Онлайн-касса ✔ Аналитика продаж и складской учет в облаке ✔ Единая система обслуживания для зала и доставки → Внедрение под ключ',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: `https://quickresto.ru${Presentation}`,
    },
  ]

  return (
    <DefaultLayout
      title="Автоматизация паба — комплексная программа учета | Quick Resto"
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  pageStyles.automationPage,
      )}
      linkCanonical="https://quickresto.ru/automation/avtomatizaciya-paba/"
      footerClassName={styles.automation__footer}
      schemaType="Product"
      schemaName={metaTags.find((tag) => tag.property === 'og:title').content}
      schemaDescription={metaTags.find((tag) => tag.property === 'og:description').content}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
      schemaUrl={metaTags.find((tag) => tag.property === 'og:url').content}
    >
      <div
        className={classNames(
					  pageStyles.pageSection,
					  styles.automation__headline,
        )}
      >
        <ProductPresentation
          leftColumn={styles.automation__presentationLeftColumn}
          title={styles.automation__presentationTitle}
        />

        <AutomationMenu className={styles.automation__menu} />
      </div>

      <SimpleFeatures
        data={featuresData.filter((feature, index) => index === 0)}
        className={pageStyles.pageSection}
      />

      <PabCtaForm
        className={pageStyles.pageSection}
        request={requestForAutoOpenPubFaster}
      />

      <SimpleFeatures
        data={featuresData.filter((feature, index) => index > 0 && index < 4)}
        className={pageStyles.pageSection}
      />

      <PubBenefits className={pageStyles.pageSection} />

      <SimpleFeatures
        data={featuresData.filter(
					  (feature, index) => index >= 4 && index < 6,
        )}
        className={pageStyles.pageSection}
      />

      <ReportFeatures className={pageStyles.pageSection} />

      <SimpleFeatures
        data={featuresData.filter(
					  (feature, index) => index >= 6 && index < 7,
        )}
        className={pageStyles.pageSection}
      />

      <AccountingFeaturesIntegrations className={pageStyles.pageSection} theme="white" />

      <ProductsFeatures className={pageStyles.pageSection} theme="white" />

      <AdditionalServices className={styles.additionalServices} theme="white" />

      <SimpleFeatures
        data={featuresData.filter((_, index) => index >= 7)}
        className={pageStyles.pageSection}
      />

      <CtaForm
        isFry={false}
        isWhiteBackground
        className={pageStyles.pageSection}
      />
    </DefaultLayout>
  )
}
